import React, { useState } from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field } from 'formik';
 import * as Yup from 'yup';
 import ErrorMesage from '../Common/ErrorMessage';
import Contact from '../../assets/images/contact.svg';
import SendMessage from '../../api/api';
import './Contact.css';

const ContactSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('What\'s your name?'),
    message: Yup.string()
      .min(5, 'Message too short')
      .required('Type your message'),
    email: Yup.string().email('Email inválido').required('What\'s your email?'),
    recaptcha: Yup.string().required('Please, verify you are a human ;)').nullable()
  });
  

export default function Process(){
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);
    const [errorSending, setErrorSending] = useState(false);

    return  <>
      <div className="max-w-screen-xl mt-4 mb-4 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-1 lg:px-1 xl:px-1 py-16 mx-auto bg-white text-gray-900  ">
        <div className="flex flex-col justify-between"  id="contact">
            <Fade bottom>
                <div>
                    <h2 className="text-4xl lg:text-5xl font-bold leading-tight">Lets talk about everything!</h2>
                    <div className="text-gray-700 mt-8">
                    <a href="mailto:info@indexceed.com">Hate forms? Send us an <span className="underline">email</span> instead.</a>
                    </div>
                    <div className="mt-8 text-center">
                        <img className="w-full mx-auto" src={Contact}  alt="Node js" />
                    </div>
                </div>
            </Fade>

        </div>
        <div className="">
            {
                !sent && !sending &&  <Fade bottom>
                <Formik
                initialValues={{
                    fullname: '',
                    message: '',
                    email: '',
                    recaptcha: '',
                }}
                validationSchema={ContactSchema}
                onSubmit={async (values) => {
                    try{
                        setSending(true);
                        await SendMessage(values.email,values.fullname,values.message);
                        setSent(true);
                    }catch(error)
                    {
                        setErrorSending(true)
                        setSending(false);
                    }
                    
                  }}
                >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        {errorSending && <ErrorMesage message="An error ocurred, try again." />}
                        <div>
                            <span className="uppercase text-sm text-gray-600 font-bold">Full Name</span>
                            <Field
                            name="fullname"
                            type="fullname"
                            className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            placeholder="Your name" />
                              <ErrorMesage message={errors.fullname}/>  
                        </div>
                        <div className="mt-8">
                            <span className="uppercase text-sm text-gray-600 font-bold">Email</span>
                            <Field
                            name="email"
                            type="email"
                            className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            placeholder="Your email" />
                            <ErrorMesage message={errors.email}/>  
                        </div>
                        <div className="mt-8">
                            <span className="uppercase text-sm text-gray-600 font-bold">Message</span>
                            <Field
                            name="message"
                            as="textarea"
                            className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            cols="30"
                            rows="10"
                            placeholder="Your Message.." />
                            <ErrorMesage message={errors.message}/>  
                        </div>
                        <div className="mt-8">
                            <span className="uppercase text-sm text-gray-600 font-bold">Verification</span>
                            <ReCAPTCHA
                                sitekey="6Lccf8cUAAAAAO9kbikr42lWvP3ZfRjSPyowKdyU"
                                onChange={(value)=>setFieldValue('recaptcha',value)}
                            />
                            <ErrorMesage message={errors.recaptcha}/>       
                        </div>
                        
                        <div className="mt-8">
                            <button
                            type="submit"
                            className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                            Send Message
                            </button>
                        </div>
                    </Form>
                 )}
                 </Formik>
          </Fade>
            }

            {
                sent &&  <div className="mt-8">
                      <h2 className="text-4xl lg:text-5xl font-bold leading-tight">Thank you, your message was sent.</h2>
            </div>
            }

            {
                sending && !sent && <div className="header-form-inner">
                <img src="https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" alt="loading" />
              </div>
            }
           
        </div>
      </div>
         </>
}