
import React from 'react';
import './Footer.css';
export default function Footer(){
return (
    <footer className="w-full py-0 px-4 mt-4 ">
    <div className="md:flex items-center justify-between my-4 md:mx-20">
        <div className="w-full">
            <p className="text-blue-500">All rights reserved</p>
        </div>
        <div className="w-full">
        <p className="inline-flex text-blue-500 md:px-2 md:pt-6">Built with 
            <svg fill="#e53e3e" viewBox="0 0 24 24"  className="w-5 h-5 mx-1 pt-px text-red-600" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
            </svg>by Indexceed.</p>
        </div>
       
        
        <div className="flex items-center">
            <a href="https://www.facebook.com/indexceed">
                <svg className="h-6 w-6 fill-current text-blue-600 mr-6" viewBox="0 0 512 512">
                    <path d="M455.27,32H56.73A24.74,24.74,0,0,0,32,56.73V455.27A24.74,24.74,0,0,0,56.73,480H256V304H202.45V240H256V189c0-57.86,40.13-89.36,91.82-89.36,24.73,0,51.33,1.86,57.51,2.68v60.43H364.15c-28.12,0-33.48,13.3-33.48,32.9V240h67l-8.75,64H330.67V480h124.6A24.74,24.74,0,0,0,480,455.27V56.73A24.74,24.74,0,0,0,455.27,32Z"/>
                </svg>
            </a>
            <a href="mailto:info@indexceed.com">
                <svg fill="none"  className="h-6 w-6 text-blue-600 mr-6" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                </svg>
            </a>
            <a href="https://twitter.com/indexceed">
                <svg className="h-6 w-6 fill-current text-blue-600 mr-6" viewBox="0 0 512 512">
                    <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z"/>
                </svg>
            </a>
        </div>
    </div>
</footer>
)
}