export default function send(email:string, nombre:string, mensaje: string){
    return fetch('https://indexceed.com:2053', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            nombre,
            mensaje
        })
    })
    
}