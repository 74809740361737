import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import Standup from '../../assets/images/standup.svg';
import Code from '../../assets/images/code.svg';
import Test from '../../assets/images/test.svg';
import './Process.css';

export default function Process(){
    return  <>
                <Fade bottom>
                    <div className="floating-block hidden lg:block shadow-2xl">
                            <div className="md:flex text-center ">
                                <div className="w-full md:w-1/3  px-16 py-4">
                                    <img className="h-48 mt-6 mb-6 mx-auto" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" alt="React js" />
                                </div>
                                <div className="w-full md:w-1/3  px-16 py-4">
                                    <img className="h-48 mt-6 mb-6 mx-auto" src="https://laravel.com/img/logotype.min.svg" alt="Laravel" />
                                </div>
                                <div className="w-full md:w-1/3  px-16 py-4">
                                    <img className="h-48 mt-6 mb-6 mx-auto" src="https://nodejs.org/static/images/logos/nodejs-new-pantone-black.svg" alt="Node js" />
                                </div>
                            </div>
                    </div>
                </Fade>
                <div className="md:mt-48" id="behindday">
                    <div className="md:flex ">
                            <Fade bottom>
                                <div className="w-full text-center">
                                    <h2 className="title-text dark-text">Our Process</h2>
                                </div>
                            </Fade>
                        </div>
                        <div className="md:flex text-center mt-12">
                            <div className="w-full md:w-1/3  px-16 py-4">
                                <Fade bottom>
                                    <h3 className="subtitle-text dark-text">Daily Standup</h3>
                                    <div className="h-60">
                                    <img className="h-48 mt-6 mb-6 mx-auto" src={Standup} alt="Daily Standup" />
                                    </div>
                                    
                                    <p className="text-gray">Great communication is the most important characteristic for success in a project, between clients, developers and users. </p>
                                </Fade>
                            </div>
                            <div className="w-full md:w-1/3 px-16  py-4">
                                <Fade bottom>
                                    <h3 className="subtitle-text dark-text">Coding</h3>
                                    <div className="h-60">
                                        <img className="h-48 mt-6 mb-6 mx-auto"  src={Code} alt="Coding" />
                                    </div>
                                    <p className="text-gray">We love coding, good practices and tons of coffee! We learn new frameworks, languages and technologies  everyday.</p>
                                </Fade>
                            </div>
                            <div className="w-full md:w-1/3 px-16 py-4">
                                <Fade bottom>
                                    <h3 className="subtitle-text dark-text">User testing</h3>
                                    <div className="h-60">
                                        <img className="h-48 mt-6 mb-6 mx-auto"  src={Test} alt="User testing" />
                                    </div>
                                    
                                    <p className="text-gray">“Geeks are people who love something so much that all the details matter.”— Marissa Mayer, Yahoo! CEO </p>
                                </Fade>
                            </div>
                        </div>
                </div>
         </>
}