import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import HeaderImage from '../../assets/images/main-2.png';
import './Header.css';

export default function Header(){
    return  <>
            <div className="md:flex lg:min-h-screen" >
                <div className="md:flex-shrink-0 hidden md:block w-full md:w-1/2 mr-6 overflow-hidden relative">
                    <img className="md:w-full" src={HeaderImage}  alt="Logo indexceed" />
                </div>
                <div className="mt-4 md:mt-0 md:pl-6 lg:pl-16">
                    <div className="uppercase tracking-wide text-sm text-indigo-600 font-bold mb-6 md:mb-6 lg:mb-24 text-center md:text-left mt-16">
                        <Fade top>
                            <nav>
                                <ul className="space-x-6 lg:space-x-12 dark-text menu-text">
                                    <li className="inline-block text-primary hover:text-secondary cursor-pointer"><a href="#behindday"> behind the day </a></li>
                                    <li className="inline-block text-primary hover:text-secondary cursor-pointer"><a href="#tools">tools</a></li>
                                    <li className="inline-block text-primary hover:text-secondary cursor-pointer"><a href="#contact">contact</a></li>
                                </ul>
                            </nav>
                        </Fade>
                    </div>

                    <div className="md:hidden mb-6">
                        <img className="w-full" width="448" height="299" src={HeaderImage}  alt="Logo indexceed" />
                    </div>
                    
                   
                    <div className="mb-4 text-center md:text-left">
                        <Fade bottom>
                            <ul className="space-x-2 dark-text small-text tec uppercase">
                                <li className="inline-block">design</li>
                                <li className="inline-block">frontend</li>
                                <li className="inline-block">backend</li>
                                <li className="inline-block">DevOps</li>
                            </ul>
                        </Fade>
                    </div>
                    <div  className="text-small main-text text-center md:text-left ">
                        <Fade bottom>
                            <h1 className="block dark-text">
                                Web 
                            </h1>
                            <h1 className="block dark-text">
                                & Mobile 
                            </h1>
                            <h1 className="block dark-text">
                                Development
                            </h1>
                        </Fade>
                    </div>
                    <div className="mt-10 text-center md:text-left mb-12">
                        <Fade bottom>
                            <a href="#portfolio" className="bg-primary hover:bg-secondary text-white font-bold py-6 px-16 rounded-full menu-text">
                                See works
                            </a>
                        </Fade>
                    </div>
                </div>
            </div>
            
        </>
}