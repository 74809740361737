import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import { useSpring, animated } from 'react-spring';
import './Tools.css';
const calc = (x:any, y:any) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x:any, y:any, s:any) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`


export default function Tools(){
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } })) as any;

    return  <>
                <div className="mt-32" id="tools">
                    <div className="md:flex ">
                            <Fade bottom>
                                <div className="w-full text-center">
                                    <h2 className="title-text dark-text">Technologies</h2>
                                </div>
                            </Fade>
                        </div>
                        <div className="md:flex text-center mt-12">
                            <div className="w-full md:w-1/3  px-16 py-4">
                                <Fade bottom>
                                    <div className="h-60  flex justify-center align-middle">
                                            <animated.div
                                                className="card mt-8"
                                                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                                                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                                                style={{ transform: props.xys.interpolate(trans) }}
                                            >
                                                <img className="h-48 mx-auto" src="https://nodejs.org/static/images/logos/nodejs-new-pantone-black.svg" alt="Node js" />
                                                </animated.div>
                                    </div>
                                   
                                </Fade>
                            </div>
                            <div className="w-full md:w-1/3 px-16  py-4">
                                <Fade bottom>
                                    <div className="h-60 flex justify-center align-middle">
                                    <animated.div
                                                className="card mt-8"
                                                id="angular"
                                                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                                                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                                                style={{ transform: props.xys.interpolate(trans) }}
                                            >
                                                <img className="h-48 mt-6 mb-6 mx-auto" src="https://laravel.com/img/logotype.min.svg" alt="Laravel" />
                                                </animated.div>
                                    </div>
                                   
                                </Fade>
                            </div>
                            <div className="w-full md:w-1/3 px-16 py-4">
                                <Fade bottom>
                                    <div className="h-60 text-center flex justify-center align-middle">
                                    <animated.div
                                                className="card mt-8"
                                                id="nodejs"
                                                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                                                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                                                style={{ transform: props.xys.interpolate(trans) }}
                                            >
                                                <img className="h-15 mx-auto" src="https://www.gstatic.com/devrel-devsite/prod/v7cbba9dce83f2a54b727914bb06dd524b80e611a7d1fe33e121163235013f003/firebase/images/lockup.png" alt="Node js" />
                                                </animated.div>
                                    </div>
                                    
                                </Fade>
                            </div>
                        </div>

                        <div className="md:flex text-center mt-12">
                            <div className="w-full md:w-1/3  px-16 py-4">
                                <Fade bottom>
                                    <div className="h-60  flex justify-center align-middle">
                                            <animated.div
                                                className="card mt-8"
                                                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                                                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                                                style={{ transform: props.xys.interpolate(trans) }}
                                            >
                                                 <img className="h-48 mx-auto" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" alt="React js" />
                                                </animated.div>
                                    </div>
                                   
                                </Fade>
                            </div>
                            <div className="w-full md:w-1/3 px-16  py-4">
                                <Fade bottom>
                                    <div className="h-60 flex justify-center align-middle">
                                    <animated.div
                                                className="card mt-8"
                                                id="angular"
                                                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                                                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                                                style={{ transform: props.xys.interpolate(trans) }}
                                            >
                                                <img className="h-40 mx-auto" src="https://angular.io/assets/images/logos/angular/shield-large.svg" alt="Laravel" />
                                                </animated.div>
                                    </div>
                                   
                                </Fade>
                            </div>
                            <div className="w-full md:w-1/3 px-16 py-4">
                                <Fade bottom>
                                    <div className="h-60 text-center flex justify-center align-middle">
                                    <animated.div
                                                className="card mt-8"
                                                id="nodejs"
                                                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                                                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                                                style={{ transform: props.xys.interpolate(trans) }}
                                            >
                                                <img className="h-48 mx-auto" src="https://vuejs.org/images/logo.png" alt="Node js" />
                                                </animated.div>
                                    </div>
                                    
                                </Fade>
                            </div>
                        </div>
                </div>
         </>
}