
import React, {useEffect,useState} from 'react';
import Header from './components/Header';
import Process from './components/Process';
import Loader from './components/Loader';
import Tools from './components/Tools';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Work from './components/Work';


function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    setTimeout(() => setLoaded(true), 2)
  },[])
  return (
    <>
      {!loaded && <Loader />}
      {
        loaded && <>
          <Header />
          <Process />
          <Tools />
          <Work />
          <Contact />
          <Footer />
        </>
      }
    </>
  );
}

export default App;
